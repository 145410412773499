const parseRRule = rrule => {
  return rrule.split(';').reduce((acc, part) => {
    const [key, value] = part.split('=');
    acc[key] = value;
    return acc;
  }, {});
};
function updateRRule(rrule, key, value) {
  const components = rrule.split(';').reduce((acc, pair) => {
    const [k, v] = pair.split('=');
    if (k) acc[k] = v; // Only add valid key-value pairs
    return acc;
  }, {});

  if (value !== undefined) {
    components[key] = value; // Update or add the new component
  } else {
    delete components[key]; // Remove the key if value is undefined
  }

  return Object.entries(components)
    .map(([k, v]) => `${k}=${v}`)
    .join(';');
}

const updateIntervalFreq = (document: any, type: string, value: any, module = 'audit') => {
  const propName = module === 'audit' ? 'recurrentAuditInfo' : 'recurrentTicketInfo';
  if (type === 'INTERVAL') {
    // Ensure `event` contains only numbers
    if (!/^\d*$/.test(value)) {
      return;
    }
    delete document.rruleInvalid;

    // Update or add INTERVAL in the rrule
    document[propName].rrule = updateRRule(document[propName].rrule, 'INTERVAL', value);
    return document[propName];
  } else if (type === 'FREQ') {
    const now = new Date();
    if (value === 'MONTHLY') {
      // Set rrule for MONTHLY frequency
      const currentDay = now.getDate();
      document[propName].rrule = updateRRule(document[propName].rrule, 'FREQ', `MONTHLY`);
      document[propName].rrule = updateRRule(
        document[propName].rrule,
        'BYDAY',
        undefined, // Remove BYDAY if switching to MONTHLY
      );
      document[propName].rrule = updateRRule(document[propName].rrule, 'BYMONTHDAY', currentDay);
      return document[propName];
    } else if (value === 'WEEKLY') {
      // Set rrule for WEEKLY frequency
      const days = ['SU', 'MO', 'TU', 'WE', 'TH', 'FR', 'SA'];
      const today = days[now.getDay()];
      document[propName].rrule = updateRRule(document[propName].rrule, 'FREQ', `WEEKLY`);
      document[propName].rrule = updateRRule(
        document[propName].rrule,
        'BYMONTHDAY',
        undefined, // Remove BYMONTHDAY if switching to WEEKLY
      );
      document[propName].rrule = updateRRule(document[propName].rrule, 'BYDAY', today);
      return document[propName];
    } else {
      document[propName].rrule = updateRRule(document[propName].rrule, 'FREQ', `DAILY`);
      document[propName].rrule = updateRRule(
        document[propName].rrule,
        'BYDAY',
        undefined, // Remove BYDAY if switching to MONTHLY
      );
      document[propName].rrule = updateRRule(
        document[propName].rrule,
        'BYMONTHDAY',
        undefined, // Remove BYDAY if switching to MONTHLY
      );
      return document[propName];
    }
  }
};

const validateInterval = value => {
  return /^[1-9]\d*$/.test(value); // Matches positive integers greater than zero
};

export { parseRRule, updateRRule, updateIntervalFreq, validateInterval };
