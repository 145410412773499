import React from 'react';
import './Walkthrough.scss';
import { AppStateContext } from '../../';
import { useNavigate, useParams } from 'react-router-dom';

import leftpanelTemplate from '../../images/left-panel-templates.png';
import leftpanelLibrary from '../../images/left-panel-library.png';
import leftpanelTicket from '../../images/left-panel-tickets.png';
import addTemplateIcon from '../../images/new-template.svg';
import templateIcon from '../../images/Template-blue.svg';
import libraryIcon from '../../images/Library-blue.svg';
import drawingsIcon from '../../images/drawings.svg';
import groupIcon from '../../images/new-group.svg';
import infoIcon from '../../images/info_blue.svg';
import { config } from '../../utils/config';

type WalkthroughProps = {
  module: string;
  isNavigationDisabled?: boolean;
  onNavigate?: () => void;
};
const Walkthrough = (props: WalkthroughProps) => {
  const { module, isNavigationDisabled, onNavigate = () => null } = props;
  const navigate = useNavigate();
  let { databaseId } = useParams<{ databaseId: string }>();
  const { language: lang, appState } = React.useContext(AppStateContext);

  const isSassEnv = config.isSassEnv;

  const toLibrary = () => {
    databaseId = databaseId || appState.get('active', 'projects');
    onNavigate();
    navigate(`/projects/${databaseId}/maps`);
  };

  const toTemplate = () => {
    databaseId = databaseId || appState.get('active', 'projects');
    onNavigate();
    navigate(`/projects/${databaseId}/templates`);
  };

  const getSupportLink = () => {
    switch (module) {
      case 'ticket':
        return lang.guide_tickets_link;
      case 'template':
      case 'audit':
        return lang.guide_audits_link;
      case 'maps':
        return lang.guide_library_link;
    }
  };

  const renderModule = (moduleType: string) => {
    switch (moduleType) {
      case 'ticket':
        return (
          <>
            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 1</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_adddrawing}</span> &nbsp;
                  <img src={libraryIcon} alt="" />
                </p>
                <p>
                  {lang.m_inf_draw_msg}{' '}
                  <button className="btn btn-primary" disabled={isNavigationDisabled} onClick={toLibrary}>
                    {lang.m_lbl_library}
                  </button>
                </p>
              </div>
            </div>

            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 2</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_whereissue}</span> &nbsp;
                  <img src={drawingsIcon} alt="" />
                </p>
                <p>{lang.m_inf_tckt_wlkmsg1}</p>
                <p>{lang.m_inf_tckt_wlkmsg2} </p>
              </div>
              <div>
                <img src={leftpanelTicket} alt="" className="illustration-image" />
              </div>
            </div>

            <div className="walkthrough-step step-3">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 3</strong>{' '}
                </span>
              </div>
              <div dangerouslySetInnerHTML={{ __html: lang.walkthrough_ticket_step3_info }}></div>
            </div>
          </>
        );

      case 'audit':
        return (
          <>
            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 1</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_createaudittemplate}</span> &nbsp;
                  <img src={templateIcon} alt="" />
                </p>
                <p>
                  {lang.m_inf_audit_msg_part1}{' '}
                  <button className="btn btn-primary" disabled={isNavigationDisabled} onClick={toTemplate}>
                    {lang.m_lbl_templates}
                  </button>{' '}
                  {lang.m_inf_audit_msg_part2}
                </p>
              </div>
              <div>
                <img src={leftpanelTemplate} alt="" className="illustration-image" />
              </div>
            </div>

            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 2</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_wchtem}</span> &nbsp;
                  <img src={templateIcon} alt="" /> &nbsp;
                  <img src={templateIcon} alt="" /> &nbsp;
                  <img src={templateIcon} alt="" /> &nbsp;
                </p>
                <p>{lang.m_inf_tem_msg1}</p>
                <p>{lang.m_inf_tem_msg2}</p>
              </div>
            </div>
          </>
        );

      case 'maps':
        return (
          <>
            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 1</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_addnewgrp}</span> &nbsp;
                  <img src={groupIcon} alt="" />
                </p>
                <p> {lang.m_inf_lib_msg} </p>
              </div>
              <div>
                <img src={leftpanelLibrary} alt="" className="illustration-image" />
              </div>
            </div>
            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 2</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_addfile}</span> &nbsp;
                  <img src={addTemplateIcon} alt="" />
                </p>
                <p>{lang.m_inf_addfile_msg}</p>
              </div>
            </div>
          </>
        );

      case 'template':
        return (
          <>
            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 1</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_addnewgrp}</span> &nbsp;
                  <img src={groupIcon} alt="" />
                </p>
                <p> {lang.m_inf_tem_msg} </p>
              </div>
              <div>
                <img src={leftpanelTemplate} alt="" className="illustration-image" />
              </div>
            </div>
            <div className="walkthrough-step">
              <div>
                <span className="step">
                  {' '}
                  <strong>{lang.m_lbl_step} 2</strong>{' '}
                </span>
              </div>
              <div>
                <p>
                  <span className="step-heading">{lang.m_lbl_addnewtemp}</span> &nbsp;
                  <img src={addTemplateIcon} alt="" />
                </p>
                <p>{lang.m_inf_addtem_msg1}</p>
                <p>{lang.m_inf_addtem_msg2}</p>
              </div>
            </div>
          </>
        );
    }
  };

  return (
    <section className={module !== 'maps' ? 'walkthrough' : 'walkthrough-lib'}>
      <section>
        <h2>{lang.m_lbl_getStart}</h2>

        <section className="walkthrough-steps">{renderModule(module)}</section>

        {isSassEnv && <p>
          <img src={infoIcon} alt="" /> &nbsp; {lang.m_inf_wlk_support}{' '}
          <a className="btn btn-edblue" rel="noreferrer" href={getSupportLink()} target="_blank">
            {lang.m_lbl_supportsite}
          </a>
        </p>}
      </section>
    </section>
  );
};

export default Walkthrough;
