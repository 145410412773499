import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import DoneAllIcon from '@material-ui/icons/DoneAll';

import { AppStateContext } from '../../../..';
import { getTimeFromTime } from '../../../../utils/dateHelper';

import progressIcon from '../../../../images/notification/progress.svg';

const SystemNotification = ({ notifications }) => {
  const { language: lang } = React.useContext(AppStateContext);

  /**
   * Prints the filename based on the object type and status.
   *
   * @param {any} obj - The object containing file information.
   * @returns {React.JSX.Element | null} - A div element with the formatted filename or null.
   * @author gaurav.rao
   */
  const printFileName = (obj: any): React.JSX.Element | null => {
    // Return null if filename is not present in the object
    if (!obj?.filename) return null;

    // Function to create a div element with the filename
    const fileNameDiv = (name: string) => <div className="filename col-md-12 col-xs-12 col-sm-12">{name}</div>;

    // Function to get the formatted filename based on object type and status
    const getFileName = (): string => {
      switch (obj.type) {
        case 'file':
        case 'glacier':
          return obj.filename;

        case 'tile':
          // Handle tile conversion messages based on status
          return obj.status === 3 ? lang.notify.map_converted.replace('__FILE__', obj.filename) : lang.notify.map_converting.replace('__FILE__', obj.filename);

        case 'user_management': {
          // Handle user management actions
          const [action, target] = obj.filename.split('|');
          const isReplaced = action === 'replace';
          const actionText = obj.status === 3 ? (isReplaced ? lang.user_mgmnt.replaced : lang.user_mgmnt.deleted) : isReplaced ? lang.m_txt_replace : lang.m_btn_delete;

          return `${actionText} ${target}`;
        }

        case 'excel':
        case 'pdf':
          // Handle Excel and PDF exports
          return lang.notify.pdf_exporting.replace('__FILE__', obj.filename);

        default:
          return obj.filename;
      }
    };

    // Return the filename wrapped in a div element
    return fileNameDiv(getFileName());
  };

  /**
   * Displays the notification time based on the notification type.
   * If the type is 'glacier' or 'user_management', formats the time; otherwise, displays the raw time.
   *
   * @param {Object} props - The props for the component.
   * @param {string} props.type - The type of the notification.
   * @param {string} props.time - The time of the notification.
   * @returns {React.JSX.Element} - A span element displaying the formatted or raw time.
   * @author gaurav.rao
   */
  const NotificationTime = ({ type, time }: { type: string; time: number }): React.JSX.Element => (
    <span>{['glacier', 'user_management'].includes(type) ? getTimeFromTime(time) : time}</span>
  );

  /**
   * Retrieves the notification title based on the type and completion status.
   *
   * @param {string} type - The type of the notification (e.g., 'pdf', 'excel', 'tile', etc.).
   * @param {boolean} isCompleted - Whether the notification indicates a completed action.
   * @returns {string} - The title of the notification based on the type and status.
   * @author gaurav.rao
   */
  const getNotificationTitle = (type: string, isCompleted: boolean): string => {
    // Title mappings based on notification type and completion status
    const titles = {
      pdf: isCompleted ? 'export_done_title' : 'export_progress_title',
      excel: isCompleted ? 'export_done_title' : 'export_progress_title',
      tile: isCompleted ? 'tile_done_title' : 'tile_progress_title',
      file: isCompleted ? 'file_done_title' : 'file_progress_title',
      user_management: isCompleted ? 'user_mgmnt_done_title' : 'user_mgmnt_progress_title',
      glacier: isCompleted ? 'glacier_done_title' : 'glacier_progress_title',
    };

    // Retrieve the key corresponding to the type
    const key = titles[type];

    // Return the language string if the key exists, otherwise return the default notification label
    return key ? lang.notify[key] : lang.m_lbl_notification;
  };

  /**
   * Renders a notification container displaying the notification's title, icon, time, and body content.
   *
   * @param {Object} props - The props for the component.
   * @param {Object} props.notification - The notification object containing details.
   * @param {string} props.notification.channelID - The unique channel ID for the notification.
   * @param {string} props.notification.type - The type of the notification.
   * @param {string} props.notification.time - The time the notification was created.
   * @param {string} props.title - The title of the notification.
   * @param {React.JSX.Element} props.icon - The icon to display with the notification.
   * @param {string} props.bodyContent - The body content of the notification.
   * @returns {React.JSX.Element} - A container element that displays the notification.
   * @author gaurav.rao
   */
  const NotificationContainer = ({ notification, title, bodyContent, icon }): React.JSX.Element => (
    <div className="notification-container system-notify" key={notification.channelID} id={notification.channelID}>
      <div className="notification__left"></div>
      <div className="notification__right">
        <div className="notification__title">
          <div>
            {icon}
            <span>{title}</span>
          </div>
          <NotificationTime type={notification.type} time={notification.time} />
        </div>
        <div className="notification__body">
          <p className="notification-info">{bodyContent}</p>
        </div>
      </div>
    </div>
  );

  /**
   * Replaces a portion of the text with a clickable link in HTML.
   *
   * @param {string} text - The full text to search within.
   * @param {string} replace - The substring to be replaced by the link.
   * @param {string} link - The URL for the anchor element.
   * @param {string} fileName - The clickable text for the anchor element.
   * @returns {React.JSX.Element} - A JSX element with the text and a clickable link.
   * @author gaurav.rao
   */
  const replaceTextByHtml = (text, replace, link: string, fileName: string): React.JSX.Element => {
    // Split the text by the replace string into two parts
    const [firstText, secondText] = text.split(replace);

    // Return the formatted HTML with the link
    return (
      <span>
        {firstText}
        <a href={link} target="_blank" rel="noreferrer">
          {fileName}
        </a>
        {secondText}
      </span>
    );
  };
  /**
   * Generates a clickable link for successfully exported PDF or ZIP files.
   *
   * @param {Object} notification - The notification object containing file information.
   * @param {string} notification.link - The download link for the file.
   * @param {string} notification.filename - The name of the exported file.
   * @returns {React.JSX.Element} - A formatted link with the file name and extension.
   * @author gaurav.rao
   */
  const successPDFZip = (notification: { link: string; filename: string }): React.JSX.Element => {
    const isPDF = notification.link.includes('.pdf');
    const extension = isPDF ? '.pdf' : '.zip';
    const shortFileName = `${notification.filename.slice(0, 15)}...${notification.link.slice(notification.link.indexOf(extension) - 5)}`;

    return replaceTextByHtml(lang.notify.export_link, '__NAME__', notification.link, shortFileName);
  };

  /**
   * Displays the notification for ongoing progress.
   *
   * @param {Object} notification - The notification object containing progress details.
   * @returns {React.JSX.Element} - A NotificationContainer component with progress information.
   * @author gaurav.rao
   */
  const ProgressState = (notification: any): React.JSX.Element => {
    const title = getNotificationTitle(notification.type, false);
    const bodyContent = printFileName(notification);

    return <NotificationContainer notification={notification} title={title} bodyContent={bodyContent} icon={<img src={progressIcon} alt="Progress icon" />} />;
  };

  /**
   * Displays the notification for completed actions (e.g., file export).
   *
   * @param {Object} notification - The notification object containing completion details.
   * @returns {React.JSX.Element} - A NotificationContainer component with success information.
   * @author gaurav.rao
   */
  const CompletedState = (notification: any): React.JSX.Element => {
    const title = getNotificationTitle(notification.type, true);
    const bodyContent = notification.link ? successPDFZip(notification) : printFileName(notification);

    return <NotificationContainer notification={notification} title={title} bodyContent={bodyContent} icon={<DoneAllIcon className="success-icon" />} />;
  };

  /**
   * Displays the notification for errors (e.g., export failure).
   *
   * @param {Object} notification - The notification object containing error details.
   * @returns {React.JSX.Element} - A NotificationContainer component with error information.
   * @author gaurav.rao
   */
  const ErrorState = (notification: any): React.JSX.Element => {
    const descriptions: Record<string, string> = {
      pdf: lang.notify.export_error.replace('__NAME__', notification.filename),
      excel: lang.notify.export_error.replace('__NAME__', notification.filename),
      tile: lang.notify.tile_error.replace('__NAME__', notification.filename),
      file: lang.notify.file_error.replace('__NAME__', notification.filename),
      user_management: lang.notify.user_mgmnt_error.replace('__NAME__', notification.filename),
      glacier: lang.notify.glacier_error.replace('__NAME__', notification.filename),
    };

    const bodyContent = descriptions[notification.type] || lang.m_lbl_error_occurred;

    return <NotificationContainer notification={notification} title="Error" bodyContent={bodyContent} icon={<CloseIcon className="error-icon" />} />;
  };

  /**
   * Renders the appropriate notification state based on the status of the object.
   * @param {Object} obj - The object containing notification details.
   * @param {number} obj.status - The status of the notification (1: Created, 2: Accepted, 3: Completed, 4: Failed).
   * @param {string} obj.channelID - The unique channel ID for the notification.
   * @returns {React.JSX.Element | null} - The corresponding notification state component or null if channelID is missing.
   * @author gaurav.rao
   *
   * Status mapping:
   * 1, 2: Created or Accepted → Shows progress (ProgressState).
   * 3: Completed → Shows success (CompletedState).
   * 4: Failed → Shows error (ErrorState).
   */
  const statusHtml = (obj: { status: number; channelID?: string }): React.JSX.Element | null => {
    // If no channelID is present, return null
    if (!obj?.channelID) return null;

    // Switch on the status to determine which state to render
    switch (obj.status) {
      case 1:
      case 2:
        return ProgressState(obj);
      case 3:
        return CompletedState(obj);
      case 4:
        return ErrorState(obj);
      default:
        return null;
    }
  };

  return Object.keys(notifications).map(key => notifications[key].isShown && statusHtml(notifications[key]));
};

export default SystemNotification;
