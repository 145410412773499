import { Permission, UserRole, IPermissionObject } from '../models/permissions';

const getRole = function (type: string, appState, databaseId?: string) {
  if (appState === undefined) {
    return;
  }
  let active = appState.get('active', 'projects') || '',
    contract = appState.get('allDb.active.contract', 'projects') || '',
    userRole = new UserRole();
  if (active) {
    if (databaseId) {
      const allProjects = appState.get('allDb', 'projects') || {};
      userRole = allProjects[databaseId].roles;
    } else {
      userRole = appState.get('allDb.active.roles', 'projects') || new UserRole();
    }
    userRole.superAdmin = appState.get('sAdmin', 'user') || false;
    if (contract) {
      contract = appState.get('allContr', 'contracts') ? appState.get('allContr', 'contracts')[contract] : {};
    }
  } else {
    userRole.superAdmin = appState.get('sAdmin', 'user') || false;
  }
  // To send the entire roles information
  if (type === 'all') {
    if (typeof contract === 'object' && contract.admin) {
      userRole.contractAdmin = true;
    }
    if (typeof contract === 'object' && contract.manager) {
      userRole.manager = true;
    }
    return userRole;
  }
  // To send the individual roles information

  if (userRole.accountable) {
    return 'projectAccountable';
  }
  if (userRole.support) {
    return 'projectSupport';
  }
  if (userRole.superAdmin) {
    return 'superAdmin';
  }
  if (typeof contract === 'object' && contract.admin) {
    return 'contractAdmin';
  }
  if (typeof contract === 'object' && contract.manager) {
    return 'manager';
  }
  if (type === 'rcir' && userRole.reporter && userRole.rci) {
    // rcir ==> [(Responsible || Consulted || Informed) + Reporter]
    return 'rciReporter';
  }
  if (userRole.reporter) {
    return 'reporter';
  }
  if (userRole.rci) {
    return 'rci';
  }
  return undefined;
};

// Function to get doc level role
const getDocLevelRole = (doc, appState) => {
  if (doc) {
    let participants, temp;
    const userId = appState.get('id', 'user');
    participants = doc.participants || {};
    const roles = ['responsible', 'consulted', 'informed', 'reporter'];

    for (let key = 0; key < roles.length; key += 1) {
      if (participants[roles[key]]) {
        if (Array.isArray(participants[roles[key]])) {
          temp = participants[roles[key]];
          for (let i = 0; i < temp.length; i += 1) {
            if (temp[i].email === userId) {
              return roles[key];
            }
          }
        } else {
          if (participants[roles[key]] && participants[roles[key]].email === userId) {
            return roles[key];
          }
        }
      }
    }
  }
  return undefined;
};

const getContract = (type: string, appState) => {
  let contract = appState.get('allDb.active.contract', 'projects') || '';
  if (contract) {
    contract = appState.get('allContr.' + contract, 'contracts');
  } else {
    contract = {};
  }
  if (type === 'module') {
    return contract.module || {};
  }
  return {};
};

const hasLicense = appState => {
  if (appState.get('active.licenseId', 'contracts')) {
    if (appState.get('allDb.active.contract', 'projects') === appState.get('active.contractId', 'contracts')) {
      return true;
    }
    return false;
  }
  return false;
};
const printPermisson = (_archived, archivedContr, currentContr) => {
  // if only isMultipleProject need to allow print hence check for other 2 scenario
  if (archivedContr && currentContr.licenseType !== 'storage') {
    return false;
  }
  return true;
};
// Function to get Permission according to Roles
const getPermission = (type, appState, doc?, isMultipleProject?): IPermissionObject => {
  let permission, userRole, archived, module, docRole, allContr, isProjectActive;
  permission = new Permission();
  userRole = getRole('all', appState);
  archived = appState.get('allDb.active.archived', 'projects');
  isProjectActive = appState.get('allDb.active.isActive', 'projects');
  allContr = appState.get('allContr', 'contracts');
  let currentContr = allContr ? appState.get('allContr', 'contracts')[appState.get('active.contractId', 'contracts')] : {};
  let archivedContr = currentContr ? currentContr.archived || !currentContr.contractActive : null;
  if (archivedContr) {
    permission.contractActive = false;
  } else {
    permission.contractActive = true;
  }
  module = getContract('module', appState);

  /* 
      Permission for Map view screen
  */
  if (type === 'ticketMap') {
    if (archived || !hasLicense(appState) || isMultipleProject || archivedContr || !isProjectActive) {
      permission.readOnly = true;
      if (!archived && !archivedContr && !isMultipleProject) {
        docRole = getDocLevelRole(doc, appState);
        if (docRole === 'reporter' && isProjectActive) {
          permission.readOnly = false;
          permission.ticket.create = true;
        }
      }
    } else {
      docRole = getDocLevelRole(doc, appState);
      if ((userRole.accountable || userRole.support || docRole === 'reporter') && isProjectActive) {
        permission.ticket.create = true;
      }
    }
    return { readOnly: permission.readOnly, ticket: permission.ticket, contractActive: permission.contractActive };
  }

  /* 
      Permission for New Ticket
  */
  if (type === 'ticketNew') {
    if (userRole.accountable || userRole.support) {
      if (!hasLicense(appState) || archived || isMultipleProject || archivedContr || !isProjectActive) {
        permission.readOnly = true;
      } else {
        permission.ticket = {
          create: true,
          comment: true,
          attachment: true,
          roles: {
            consulted: true,
            informed: true,
            responsible: true,
          },
          dates: true,
          status: true,
          archive: true,
          access: true,
          title: true,
          description: true,
          customFields: true,
          recurrent: true,
          isProofMandatory: currentContr.pricePlan === 'EXPERT' || currentContr.pricePlan === 'PRO',
        };
      }
    } else if (userRole.reporter) {
      permission.ticket.create = true;
      permission.ticket.comment = true;
      permission.ticket.attachment = true;
      permission.ticket.title = true;
      permission.ticket.description = true;
    }
    return { readOnly: permission.readOnly, ticket: permission.ticket };
  }
  /* 
      Permission for Edit Ticket
  */
  if (type === 'ticketEdit') {
    if (archived || isMultipleProject || archivedContr || !isProjectActive) {
      permission.readOnly = true;
      permission.print = printPermisson(archived, archivedContr, currentContr);
    } else {
      if (userRole.accountable || userRole.support) {
        if (!hasLicense(appState) && !archivedContr) {
          permission.readOnly = true;
        } else if (doc.archive) {
          permission.ticket.archive = true;
          permission.ticket.tags = false;
        } else {
          permission.ticket = {
            create: true,
            comment: true,
            attachment: true,
            roles: {
              consulted: true,
              informed: true,
              responsible: true,
            },
            dates: true,
            status: true,
            archive: true,
            access: true,
            title: true,
            description: true,
            tags: true,
            save: true,
            customFields: true,
            removeAttachment: true,
          };
        }
      } else {
        if (doc.archive) {
          permission.readOnly = true;
          permission.ticket.tags = false;
        } else {
          docRole = getDocLevelRole(doc, appState);
          const isReporter = doc.participants?.reporter && doc.participants.reporter.email === appState.get('id', 'user');
          if (docRole === 'responsible') {
            permission.ticket.comment = true;
            permission.ticket.attachment = true;
            permission.ticket.status = true;
            permission.ticket.roles.consulted = true;
            permission.ticket.tags = false;
            permission.ticket.save = true;
            permission.ticket.customFields = true;
          }
          if (docRole === 'reporter' || isReporter) {
            permission.ticket.create = true;
            permission.ticket.comment = true;
            permission.ticket.attachment = true;
            permission.ticket.tags = false;
            permission.ticket.title = true;
            permission.ticket.customFields = false;
            permission.ticket.description = true;
            permission.ticket.save = true;
          }
          if (docRole === 'consulted') {
            permission.ticket.comment = true;
            permission.ticket.attachment = true;
            permission.ticket.tags = false;
            permission.ticket.save = false;
          }
        }
      }
    }
    return { readOnly: permission.readOnly, ticket: permission.ticket, contractActive: permission.contractActive, print: permission.print };
  }
  /* 
      Permission for New Audit
  */
  if (type === 'auditNew') {
    if (!hasLicense(appState) || archived || archivedContr || !isProjectActive) {
      permission.readOnly = true;
      permission.print = printPermisson(archived, archivedContr, currentContr);
    } else if (userRole.accountable || userRole.support) {
      permission.audit = {
        create: true,
        edit: true,
        read: true,
        archive: true,
        access: true,
        dates: true,
        tags: true,
        maps: true,
        roles: {
          informed: true,
          responsible: true,
        },
      };
    }
    return { readOnly: permission.readOnly, audit: permission.audit, contractActive: permission.contractActive, print: permission.print };
  }
  /* 
      Permission for Edit Audit
  */
  if (type === 'auditEdit') {
    if (archived || isMultipleProject || archivedContr || !isProjectActive) {
      permission.readOnly = true;
      permission.print = printPermisson(archived, archivedContr, currentContr);
    } else {
      if (userRole.contractAdmin) {
        permission.audit.delete = true;
      }
      if ((userRole.accountable || userRole.support) && hasLicense(appState)) {
        if (doc.archive) {
          permission.audit.archive = true;
          permission.audit.dates = false;
          permission.audit.delete = true;
        } else if (doc.completed) {
          permission.audit.complete = true;
          permission.audit.reopen = true;
          permission.audit.dates = false;
          permission.audit.archive = true;
          permission.audit.roles = {
            informed: true,
            responsible: false,
          };
          permission.audit.edit = false;
        } else {
          permission.audit.create = true;
          permission.audit.attachment = true;
          permission.audit.edit = true;
          permission.audit.read = true;
          permission.audit.maps = true;
          permission.audit.delete = true;
          permission.audit.archive = true;
          permission.audit.dates = true;
          permission.audit.roles = {
            informed: true,
            responsible: true,
          };
          permission.audit.access = true;
          permission.audit.tags = true;
        }
      } else {
        if (doc.archive) {
          permission.readOnly = true;
        } else {
          docRole = getDocLevelRole(doc, appState);
          if (docRole === 'responsible') {
            permission.audit.dates = false;
            if (doc.completed) {
              permission.audit.complete = true;
              permission.audit.reopen = true;
            } else {
              permission.audit.read = true;
              permission.audit.create = true;
              permission.audit.edit = true;
              permission.audit.maps = true;
            }
          }
          if (docRole === 'informed') {
            permission.audit.read = true;
            permission.audit.dates = false;
          }
        }
      }
    }
    return { readOnly: permission.readOnly, audit: permission.audit, contractActive: permission.contractActive, print: permission.print };
  }

  if (type === 'modAccess') {
    if (archived) {
      permission.readOnly = true;
      permission.print = printPermisson(archived, archivedContr, currentContr);
    }
    if (archivedContr) {
      permission.print = printPermisson(archived, archivedContr, currentContr);
      permission.contractActive = false;
    }
    if (hasLicense(appState)) {
      permission.licActive = true;
    }
    permission.modAccess.ticket = true;
    if (userRole.contractAdmin || userRole.accountable || userRole.support) {
      permission.modAccess.map = true;
    }
    if (module.reporter && (userRole.contractAdmin || userRole.accountable || userRole.support)) {
      permission.modAccess.reporter = true;
    }
    if (module.audit || module.audits) {
      permission.modAccess.audit = true;
      if (userRole.contractAdmin || userRole.accountable || userRole.support) {
        permission.modAccess.template = true;
      }
    }
    if (!(userRole.accountable || userRole.support) && !userRole.contractAdmin && (userRole.rci || userRole.reporter || userRole.manager)) {
      permission.modAccess.template = false;
      permission.modAccess.map = false;
      permission.modAccess.reporter = false;
      // For Reporter hide even Audit module too.
      if (userRole.reporter && !userRole.rci) {
        permission.modAccess.audit = false;
      }
    }
    return {
      readOnly: permission.readOnly,
      licActive: permission.licActive,
      modAccess: permission.modAccess,
      contractActive: permission.contractActive,
      print: permission.print,
    };
  }

  /* 
    Permission for New Project
  */
  if (type === 'newProject') {
    let contractActive = appState.get('active.contractId', 'contracts') || '';
    if (contractActive) {
      contractActive = appState.get('allContr.' + contractActive, 'contracts') || {};
    } else {
      contractActive = {};
    }
    if (contractActive.admin) {
      permission.project.create = true;
      permission.project.edit = true;
    }
    return { project: permission.project, readOnly: false };
  }
  /* 
    Permission for Edit Project
  */
  if (type === 'editProject') {
    if ((userRole.accountable && hasLicense(appState)) || userRole.contractAdmin || userRole.superAdmin || archivedContr) {
      permission.project.create = true;
      permission.project.edit = true;
    }
    return { project: permission.project, readOnly: false };
  }

  if (type === 'library') {
    if (!hasLicense || archived || isMultipleProject || archivedContr) {
      permission.readOnly = true;
    } else if ((userRole.accountable || userRole.support) && hasLicense(appState)) {
      permission.library.create = true;
      permission.library.edit = true;
    }
    return { library: permission.library, readOnly: permission.readOnly };
  }
  if (type === 'template') {
    if (archived || isMultipleProject || archivedContr) {
      permission.readOnly = true;
      permission.print = printPermisson(archived, archivedContr, currentContr);
    } else if ((userRole.accountable || userRole.support) && hasLicense(appState)) {
      permission.template.create = true;
      permission.template.edit = true;
    } else if (userRole.contractAdmin) {
      permission.template.create = true;
      permission.template.edit = true;
    }
    return { template: permission.template, readOnly: permission.readOnly, contractActive: permission.contractActive, print: permission.print };
  }

  if (type === 'reporter') {
    if (!hasLicense || archived || isMultipleProject || archivedContr) {
      permission.readOnly = true;
    }
    return { readOnly: permission.readOnly };
  }
  // If case dose not match, Return empty object.
  return { readOnly: false };
};

export { getRole, getDocLevelRole, getContract, hasLicense, getPermission };
