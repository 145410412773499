import { config } from './config';

const setFavicon = (): void => {
  // Define favicon mappings based on environment variable
  const faviconMap: Record<string, string> = {
    prod: '/favicon.png',
    secured: '/favicon-secured.png',
  };

  // Get environment variable (default to 'prod' if undefined)
  const env: string = config.isSassEnv ? 'prod' : 'secured';
  const faviconUrl: string = faviconMap[env] || faviconMap['prod'];

  // Find the existing favicon link element
  let link: HTMLLinkElement | null = document.querySelector("link[rel~='icon']");
  if (!link) {
    link = document.createElement('link');
    link.rel = 'icon';
    document.head.appendChild(link);
  }

  // Update the favicon URL
  link.href = faviconUrl;
};

export default setFavicon;
