export interface IOperationModel {
  type: string;
  date: string;
  message: string;
  author: string;
  isPastWarning: boolean;
  attachments: IAttachment[];
  comment?: Obj;
}

export interface IAttachment {
  type: string;
  name: string;
  url: string;
  originalUrl: string;
  timestamp: string;
}

export class OperationTimeline implements IOperationModel {
  public type: string;
  public date: string;
  public message: string;
  public author: string;
  public isPastWarning: boolean;
  public attachments: IAttachment[];
  public comment?: Obj;
  constructor(author: string, date: string, type: string) {
    this.type = type || '';
    this.date = date || '';
    this.author = author || '';
    this.message = '';
    this.attachments = [];
    this.comment = {};
  }
}

export class AttachmentTimeline implements IAttachment {
  public type: string;
  public name: string;
  public url: string;
  public timestamp: string;
  public originalUrl: string;
  constructor(data: any = {}) {
    this.type = data.type || '';
    this.name = data.name || '';
    this.url = data.url || '';
    this.timestamp = data.timestamp || '';
    this.originalUrl = data.originalUrl || '';
  }
}

export interface TimelineObject {
  timeline_obj: Obj;
  commentObj: Obj;
  image_obj: Obj;
  doc_obj: Obj;
}

export interface Obj {}
