import { setUserInfo, clearSwithUser } from '../../pages/authentication/AuthService';
import { apiUrl } from '../../utils/api';
import { sendGetRequest } from '../../utils/requestController';

export const switchUser = async (email: string, appState) => {
  const switched = await impersonateUser(email);
  const superadminEmail = appState.get('switch.current', 'user') || appState.get('id', 'user');
  if (switched) {
    appState.set('switch.user', email, 'user');
    appState.set('switch.current', superadminEmail, 'user');
    appState.set('id', email, 'user');
    // Set switched user access token here
    appState.set('switch.currentaccess_token', appState.get('accessToken', 'user'), 'user');
    appState.set('switch.newaccess_token', switched.access_token, 'user');
    appState.set('accessToken', switched.access_token, 'user');

    //get the original user data back
    const userEmail = appState.get('id', 'user');
    const userToken = appState.get('accessToken', 'user');
    const userData = { user_email: userEmail, access_token: userToken };
    await setUserInfo(userData, appState);
    return true;
  } else {
    if (appState.get('switch.user', 'user')) {
      appState.set('accessToken', appState.get('switch.newaccess_token', 'user'), 'user');
    }
  }
};

const impersonateUser = email => {
  const link = apiUrl.users + '/impersonate?emailAddress=' + email.toLowerCase().trim();
  return sendGetRequest(link);
};

export const switchBackUser = async appState => {
  // Clear the switched user status
  await clearSwithUser();
  appState.set('id', appState.get('switch.current', 'user'), 'user');
  appState.set('accessToken', appState.get('switch.currentaccess_token', 'user'), 'user');
  appState.del('switch', 'user');
  // Clear the dummy license set for switched user
  appState.set('active.licenseId', '', 'contracts');

  //get the original user data back
  const userEmail = appState.get('id', 'user');
  const userToken = appState.get('accessToken', 'user');
  const userData = { user_email: userEmail, access_token: userToken };
  return setUserInfo(userData, appState);
};
export const checkIsScreenMatchesType = (type, appState, location) => {
  const params = new URLSearchParams(location.search);
  switch (appState.get('module')) {
    case 'ticket':
      let allowedTicketTypes = [];
      if (params.get('view') === 'map' || params.get('view') === 'googleMap') {
        allowedTicketTypes = ['ticketChooseStep', 'ticketCreate', 'ticketLocate', 'ticketCreated', 'libraryNotification', 'audit', 'reporterTicketCreated', 'ticketSelect'];
      } else {
        allowedTicketTypes = [
          'ticketChooseStep',
          'ticketFindDraw',
          'libraryNotification',
          'libraryIntro',
          'libraryGroup',
          'ticketSelect',
          'ticketComments',
          'ticketAssign',
          'ticketStatusChange',
          'ticketArchive',
          'respnsibleTicketStatusChange',
          'rciCommentAdded',
          'informedCheckDetails',
          'reporterCheckDetails',
          'reporterTicketCreation',
          'reporterTicketCreated',
        ];
      }
      return allowedTicketTypes.includes(type);
    case 'maps':
      const allowedMapTypes = ['libraryIntro', 'libraryGroup', 'libraryMap', 'libraryUpload', 'librarySelectDrawing', 'libraryNotification'];
      return allowedMapTypes.includes(type);
    case 'projects':
      const allowedProjectTypes = ['ticket', 'libraryNotification'];
      return allowedProjectTypes.includes(type);
    case 'audit':
      const allowedAuditsTypes = [
        'audit',
        'auditGroup',
        'auditSelect',
        'selectTemplate',
        'startAudit',
        'auditCreated',
        'libraryNotification',
        'reporter',
        'templateGroup',
        'auditPageOneEditName',
        'auditPageOneAddDrawing',
        'auditPageOneAddDetails',
        'auditPageOneSave',
        'auditPageTwoAnsQues',
        'auditPageTwoSave',
        'auditInformedCheckDetails',
        'auditResInfoPageOneNext',
        'auditTemplateSelect',
        'auditCompleted',
      ];
      return allowedAuditsTypes.includes(type);
    case 'template':
      const allowedTemplateTypes = [
        'templateGroup',
        'templateCreate',
        'goToAudit',
        'libraryNotification',
        'templateName',
        'templateAuditType',
        'templateAddCategoryName',
        'templateSaveDraftOrPublish',
        'templateImportSuccess',
      ];
      return allowedTemplateTypes.includes(type);
    case 'reporter':
      const allowedReporterTypes = ['reporter', 'reporterAdd', 'reporterMap', 'reporterCreated', 'libraryNotification'];
      return allowedReporterTypes.includes(type);
    default:
      return true;
  }
};

export const checkIfFreeTrialGuideShown = (appState, freeTrailGuideData, db = '') => {
  const checkDatabase = db || freeTrailGuideData.databaseId;

  if (appState.get('active', 'projects') === checkDatabase && appState.get('module') !== 'projects') {
    return true;
  }
  return false;
};

/**
 * Helper function to generate the appropriate path for navigation
 */
export const getModulePath = (
  module: string,
  database: string,
  id: string,
  groupId?: string,
  mapTemplateId?: string
): string | null => {
  switch (module) {
    case 'IB.EdBundle.Document.Ticket': {
      const params = new URLSearchParams();
      if (groupId) params.append('groupId', groupId);
      if (mapTemplateId) params.append('mapId', mapTemplateId);

      return `/projects/${database}/tickets/${id}${params.toString() ? `?${params}` : ''}`;
    }
    case 'IB.EdBundle.Document.Audit': {
      const params = new URLSearchParams();
      if (groupId) params.append('groupid', groupId);
      if (mapTemplateId) params.append('template', mapTemplateId);

      return `/projects/${database}/audits/${id}${params.toString() ? `?${params}` : ''}`;
    }
    default:
      return `/projects/edit/${database}/${id}`;
  }
};
