import logoIcon from './../images/secured/secured_logo.svg';

export const config = {
  maxPdfPrintCount: 250,
  maxPageSizeCount: 9999,
  maxTicketDescpSize: 1000,
  successBarTimeout: 3000,
  maxIndividualTagSize: 100,
  maxTicketTitleSize: 500,
  maxTagSize: 15,
  pageSize: 20,
  undoFunctionTimeout: 6000,
  bulkUploadLimit: 20,
  isSassEnv: process.env.REACT_APP_IS_SASS === 'true',
  removableParamforAPI: ['view', 'aggs_type', 'aggs_value', 'dummy', 'activeDatabase', 'toggle'],
  logoImagePath: process.env.REACT_APP_IS_SASS === 'true' ? '/dutchview-files/edcontrols/ed_icon.png' : logoIcon,
  languages: {
    en: 'English',
    nl: 'Dutch',
    de: 'German',
    fr: 'French',
    pl: 'Polish',
    // sq: 'Albanian'
  },
  walkthroughModules: ['ticket', 'audit', 'maps', 'template', 'reporter'],
  fileUploadChunkSize: 10 * 1024 * 1024, // 10 Mb -> Min should be 5mb as per AWS S3
  maxFileSize: 500 * 1024 * 1024, // 500MB
  recurrentEmail: 'recurrence.audit@dutchview.com',
  recurrentTicketEmail: 'recurrence.ticket@dutchview.com',
};
