import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@material-ui/core';

import { AppStateContext } from '../../..';

import './EDConfirmationDialog.scss';

import infoIcon from '../../../images/info_icon.svg';
import closeImage from '../../../images/close_icon.svg';

export interface ConfirmationOptions {
  icon?: string;
  title: string;
  showIcon?: boolean;
  closeIcon?: boolean;
  catchOnCancel?: boolean;
  description: string[];
  successLabel?: string;
  cancelLabel?: string;
  hideCancel?: boolean;
  customClass?: string;
  maxWidthProp?: false | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | undefined;
}

interface ConfirmationDialogProps extends ConfirmationOptions {
  open: boolean;
  onSubmit: () => void;
  onClose: () => void;
  onClosed?: () => void;
  handleXClick?: () => void;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  title,
  description,
  onSubmit,
  onClose,
  successLabel,
  cancelLabel,
  hideCancel,
  customClass,
  onClosed,
  handleXClick,
  maxWidthProp,
  icon,
  showIcon = true,
  closeIcon = false,
}) => {
  const { language: lang } = React.useContext(AppStateContext); // Required Legacy Data
  return (
    <Dialog
      open={open}
      onExited={onClosed}
      maxWidth={maxWidthProp ? maxWidthProp : 'sm'}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={'ed-confirmation-dialog ' + (customClass ? customClass : '')}>
      <DialogTitle id="alert-dialog-title">
        {showIcon && <img src={icon || infoIcon} alt="" />}
        <span className={showIcon ? '' : 'ml-0'}>{title}</span>
        {closeIcon && <img src={closeImage} className="dialog-close" alt="" onClick={handleXClick} />}
      </DialogTitle>
      {description.length > 0 && (
        <DialogContent>
          <DialogContentText>
            {description.map((descrp, i) => (
              <span key={i}>{descrp}</span>
            ))}
          </DialogContentText>
        </DialogContent>
      )}
      <DialogActions>
        {!hideCancel && (
          <button className={`btn ${cancelLabel === lang.m_lbl_delete_contract ? 'btn--save5' : 'btn--cancel3'}`} onClick={onClose} id="dialog-cancel">
            {cancelLabel ? cancelLabel : lang.m_btn_cancel}
          </button>
        )}
        <button className={`btn ${successLabel === lang.m_btn_delete ? 'btn--save5' : 'btn--save2'}`} onClick={onSubmit} id="dialog-ok">
          {successLabel ? successLabel : lang.m_btn_ok}
        </button>
      </DialogActions>
    </Dialog>
  );
};
