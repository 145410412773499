import { apiUrl } from './api';
import { config } from './config';
import { appendToken, generateFullName } from './utils';
import { getDocLevelRole, getRole } from './permissionController';
import { convertDate, dateFormatter, generateDateTime } from './dateHelper';
import { AttachmentTimeline, IAttachment, IOperationModel, OperationTimeline } from '../models/timeline';
import { parseRRule } from './recurrenceHelper';

const timelineForMapsModule = (obj, appState) => {
  /*
        With map manipulation, Map name change, group name change
        map archive or unarchive, map moved from one group to another
        will be recorded into timeline.
    */
  let timeline_obj: any = {};
  // Actor, Person who is responsible for the action
  timeline_obj.actor = {
    email: appState.get('id', 'user'),
    interface: 'web',
    interfaceVersion: appState.get('version', 'product'),
    type: 'IB.EdBundle.Document.Person',
  };
  // Operation types, old and new value, time.
  timeline_obj.oldValue = obj.old_value;
  timeline_obj.newValue = obj.new_value;
  timeline_obj.operation = obj.operation;
  timeline_obj.operationType = obj.operation_type;
  timeline_obj.time = obj.current_time;
  if (obj.operation_type === 'nameChange' || obj.operation_type === 'groupChange') {
    timeline_obj.type = 'IB.EdBundle.Document.Change';
  }
  if (obj.operation_type === 'archived' || obj.operation_type === 'unarchived') {
    timeline_obj.type = 'IB.EdBundle.Document.Transition';
  }
  return timeline_obj;
};

const prepareOperationTimeline = (operation, operation_type, old_value, new_value, current_time, _ShareComments, operationObject) => {
  let operation_obj: any = {},
    changedProperties: Array<string> = [],
    oldValues: any = [],
    newValues: any = [];
  operation_obj.changedProperties = [];
  operation_obj.oldValues = [];
  operation_obj.newValues = [];

  // check if changedProperties already exists
  if (operationObject.hasOwnProperty('changedProperties')) {
    changedProperties = operationObject.changedProperties;
  }
  if (operationObject.hasOwnProperty('oldValues')) {
    oldValues = operationObject.oldValues;
  }
  if (operationObject.hasOwnProperty('newValues')) {
    newValues = operationObject.newValues;
  }
  // Assign
  if (operation_type === 'assign') {
    let temp: any = [];
    let oldData: Array<string> = [];
    if (new_value && new_value.length > 0) {
      for (let i = 0; i < new_value.length; i++) {
        temp.push(new_value[i].email);
      }
    }
    if (old_value !== null && typeof old_value === 'object') {
      const tempOld = old_value.slice();
      for (let i = 0; i < tempOld.length; i++) {
        oldData.push(tempOld[i].email);
      }
    }
    if (operation === 'participants.consulted') {
      const index = changedProperties.indexOf('consulted');
      if (index > -1) {
        // update exisiting title
      } else {
        // Add title
        changedProperties.push('consulted');
        oldValues.push(oldData);
        newValues.push(temp);
      }
    }
    if (operation === 'participants.informed') {
      let index = changedProperties.indexOf('informed');
      if (index > -1) {
        // update exisiting title
      } else {
        // Add title
        changedProperties.push('informed');
        oldValues.push(oldData);
        newValues.push(temp);
      }
    }
    if (operation === 'participants.responsible') {
      let index = changedProperties.indexOf('responsible');
      if (index > -1) {
        // update exisiting title
      } else {
        // Add title
        changedProperties.push('responsible');
        oldValues.push(old_value);
        newValues.push(new_value);
      }
    }
  }
  // Title
  if (operation_type === 'titleAdd' || operation_type === 'titleChange') {
    let index = changedProperties.indexOf('title');
    if (index > -1) {
      // update exisiting title
    } else {
      // Add title
      changedProperties.push('title');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  //Description
  if (operation_type === 'descriptionAdd' || operation_type === 'descriptionChange') {
    let index = changedProperties.indexOf('description');
    if (index > -1) {
      // update exisiting title
    } else {
      // Add title
      changedProperties.push('description');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  // Due date change
  if (operation_type === 'dueDateChange') {
    let index = changedProperties.indexOf('duedate');
    if (index > -1) {
      // update exisiting title
    } else {
      // Add title
      changedProperties.push('duedate');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  // status change
  if (operation_type === 'statusAdd') {
    let index = changedProperties.indexOf('status');
    if (index > -1) {
      // update exisiting title
      oldValues[index] = old_value;
      newValues[index] = new_value;
    } else {
      // Add title
      changedProperties.push('status');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  // Tags
  if (operation_type === 'tagsAdd' || operation_type === 'tagsChanged') {
    let index = changedProperties.indexOf('tags');
    if (index > -1) {
      // update exisiting title
    } else {
      // Add title
      changedProperties.push('tags');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  // comments add
  if (operation_type === 'comments') {
    let index = changedProperties.indexOf('comments');
    if (index > -1) {
    } else {
      // Add title
      changedProperties.push('comment');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  if (operation_type === 'removedComment') {
    let index = changedProperties.indexOf('removedComment');
    if (index > -1) {
    } else {
      // Add title
      changedProperties.push('removedComment');
      changedProperties.push('commentTimestamp');
      oldValues.push(old_value);
      oldValues.push(null);
      newValues.push(new_value);
      newValues.push(current_time);
    }
  }
  if (operation_type === 'removedContentAttachment') {
    let index = changedProperties.indexOf('removedContentAttachment');
    if (index > -1) {
    } else {
      // Add title
      changedProperties.push('removedComment');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  if (operation_type === 'archived') {
    let index = changedProperties.indexOf('comments');
    if (index > -1) {
    } else {
      // Add title
      changedProperties.push('archived');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }
  if (operation_type === 'unarchived') {
    let index = changedProperties.indexOf('comments');
    if (index > -1) {
    } else {
      // Add title
      changedProperties.push('archived');
      oldValues.push(old_value);
      newValues.push(new_value);
    }
  }

  if (operation_type === 'reopen') {
    let index = changedProperties.indexOf('reopen');
    if (index > -1) {
      // update exisiting title
    } else {
      // Add title
      changedProperties.push('reopen');
      oldValues.push(null);
      newValues.push(new_value);
    }
  }
  // Custom fields
  if (operation_type === 'customFields') {
    let index = changedProperties.indexOf(`customFields:${new_value.label}`);
    if (index > -1) {
      // update exisiting title
    } else {
      // Add title
      changedProperties.push(`customFields:${new_value.label}`);
      if (['singleSelectMultipleChoice', 'multiSelectMultipleChoice'].includes(new_value.type)) {
        const getTextById = (options, value) => {
          // Check if value is defined and is an array
          if (!value || !Array.isArray(value)) {
            return [];
          }

          // Filter options where the id is included in value
          const matchedTexts = options
            .filter(option => value.includes(option.id)) // Find all matching options
            .map(option => option.text); // Extract the text of matched options

          return matchedTexts; // Return the array of matched texts
        };

        if (old_value) {
          old_value.value = getTextById(new_value.richOptions, old_value?.value) || null;
        }
        if (new_value) {
          new_value.value = getTextById(new_value.richOptions, new_value?.value) || null;
        }
      }

      oldValues.push(old_value?.value || null);
      newValues.push(new_value?.value || null);
    }
  }

  if (operation_type === 'recurrentTicketInfo') {
    if (old_value?.periodStart !== new_value.periodStart) {
      changedProperties.push('recurrentPeriodStart');
      oldValues.push(old_value?.periodStart || null);
      newValues.push(new_value?.periodStart || null);
    }
    if (old_value?.periodEnd !== new_value.periodEnd) {
      changedProperties.push('recurrentPeriodEnd');
      oldValues.push(old_value?.periodEnd || null);
      newValues.push(new_value?.periodEnd || null);
    }
    if (old_value?.rrule !== new_value.rrule) {
      changedProperties.push('rrule');
      oldValues.push(old_value?.rrule || null);
      newValues.push(new_value?.rrule || null);
    }
    if (old_value?.isRecurrenceEnabled !== new_value.isRecurrenceEnabled) {
      changedProperties.push('isRecurrenceEnabled');
      oldValues.push(old_value?.isRecurrenceEnabled || false);
      newValues.push(new_value?.isRecurrenceEnabled || false);
    }
  }
  // update and return operation object
  operation_obj.changedProperties = changedProperties;
  operation_obj.oldValues = oldValues;
  operation_obj.newValues = newValues;

  return operation_obj;
};

const getContentAttachedImages = (ticket_info, imageObj, allObj, accessToken, databaseId) => {
  let image_date;
  if (ticket_info.content && ticket_info.content.attachments && ticket_info.content.attachments.length !== 0) {
    image_date = convertDate(ticket_info.dates.creationDate).replace(/\//g, '-').replace(/,-/, ' ');
    if (!imageObj[image_date]) {
      imageObj[image_date] = [];
    }
    if (!allObj[image_date]) {
      allObj[image_date] = [];
    }
    ticket_info.content.attachments.forEach(image => {
      let doc = { url: '', type: 'img', name: image };
      doc.url = appendToken(apiUrl.secureData + databaseId + '/' + ticket_info._id + '/' + image, accessToken);
      imageObj[image_date].push(doc);
      allObj[image_date].push(doc);
    });
  }
  return { imageObj, allObj };
};

const generateTimelineData = (data, lang_keys, appState, isAudit, accessToken, databaseId, personList) => {
  let timeline_obj: IOperationModel[] = [],
    tempTimeline_obj: IOperationModel,
    author,
    date,
    operations = data.operation,
    comments = data.comments;
  let image_obj = {},
    doc_obj = {},
    all_obj = {},
    commentObj: IOperationModel[] = [];
  const generatePersonMessage = (value, type, property) => {
    let message = '';
    if (type === 'revoke') {
      message = lang_keys.m_txt_timeline_revokerole_web.replace('__PERSON_2__', generateFullName(value, appState, lang_keys, personList));
      message = message.replace('__ROLE__', property);
    } else {
      message = lang_keys.m_txt_timeline_assignrole_web.toLowerCase().replace('__person_2__', generateFullName(value, appState, lang_keys, personList));
      message = message.replace('__role__', property);
    }
    return message;
  };

  const generateInformedConsultedOperation = (operation, property, index) => {
    let type_lang_key;
    if (property === 'consulted') {
      type_lang_key = lang_keys.m_lbl_cons;
    } else if (property === 'informed') {
      type_lang_key = lang_keys.m_lbl_info;
    }
    if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
      operation.newValues[index].forEach(value => {
        tempTimeline_obj = new OperationTimeline(author, date, property);
        tempTimeline_obj.message = generatePersonMessage(value, 'assign', type_lang_key);
        timeline_obj.push(tempTimeline_obj);
      });
    } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
      // Removing all consulted role
      operation.oldValues[index].forEach(value => {
        tempTimeline_obj = new OperationTimeline(author, date, property);
        tempTimeline_obj.message = generatePersonMessage(value, 'revoke', type_lang_key);
        timeline_obj.push(tempTimeline_obj);
      });
    } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
      operation.oldValues[index] &&
        operation.oldValues[index].forEach(value => {
          if (!operation.newValues[index].includes(value)) {
            tempTimeline_obj = new OperationTimeline(author, date, property);
            tempTimeline_obj.message = generatePersonMessage(value, 'revoke', type_lang_key);
            timeline_obj.push(tempTimeline_obj);
          }
        });
      operation.newValues[index] &&
        operation.newValues[index].forEach(value => {
          if (!operation.oldValues[index].includes(value)) {
            tempTimeline_obj = new OperationTimeline(author, date, property);
            tempTimeline_obj.message = generatePersonMessage(value, 'assign', type_lang_key);
            timeline_obj.push(tempTimeline_obj);
          }
        });
    }
  };

  const generateCommentOperation = (comment, ticket_audit_object, operation, accessToken?) => {
    let url,
      fileName,
      attachmentsModel: IAttachment,
      isImg = false,
      isFile = false,
      attachments: IAttachment[] = [];
    let image_time = convertDate(operation.time).replace(/\//g, '-').replace(/,-/, ' ');
    tempTimeline_obj = new OperationTimeline(author, date, 'comment');
    if (comment.note) {
      if (isAudit) {
        tempTimeline_obj.message = lang_keys.m_txt_timeline_commented_web.toLowerCase().replace('__comment_text__', comment.note);
      } else {
        tempTimeline_obj.message = comment.note;
      }
    }
    if (comment.attachments && comment.attachments.length > 0) {
      comment.attachments.forEach(file => {
        let doc = {};
        attachmentsModel = new AttachmentTimeline();
        fileName = file.split('.');
        if (ticket_audit_object._attachments && ticket_audit_object._attachments.hasOwnProperty(file)) {
          url = appendToken(apiUrl.secureData + databaseId + '/' + ticket_audit_object._id + '/' + file, accessToken);
        } else if (ticket_audit_object._attachments && ticket_audit_object._attachments.hasOwnProperty(getAlternateFilename(file))) {
          file = getAlternateFilename(file); //iOS Comment issue fix - Filename is comment section is stored with Capital letters
          url = appendToken(apiUrl.secureData + databaseId + '/' + ticket_audit_object._id + '/' + file, accessToken);
        }
        attachmentsModel.originalUrl = url;
        if (ticket_audit_object._attachments && ticket_audit_object._attachments.hasOwnProperty(fileName.join('.256x192.'))) {
          url = appendToken(apiUrl.secureData + databaseId + '/' + ticket_audit_object._id + '/' + fileName.join('.256x192.'), accessToken);
        }
        attachmentsModel.url = url;
        tempTimeline_obj.comment = comment;
        attachmentsModel.name = file;
        attachmentsModel.timestamp = operation.time;
        doc['url'] = attachmentsModel.originalUrl;
        doc['name'] = file;
        doc['timestamp'] = operation.time;
        switch (fileName[1]) {
          case 'png':
          case 'webp':
          case 'jpg':
          case 'jpeg':
          case 'svg':
            attachmentsModel.type = 'img';
            doc['type'] = 'img';
            isImg = true;
            if (!image_obj[image_time]) {
              image_obj[image_time] = [];
            }
            if (!all_obj[image_time]) {
              all_obj[image_time] = [];
            }
            image_obj[image_time].push(doc);
            all_obj[image_time].push(doc);
            break;
          case 'xls':
          case 'xlsx':
            attachmentsModel.type = 'xls';
            doc['type'] = 'xls';
            isFile = true;
            if (!doc_obj[image_time]) {
              doc_obj[image_time] = [];
            }
            doc_obj[image_time].push(doc);
            if (!all_obj[image_time]) {
              all_obj[image_time] = [];
            }
            all_obj[image_time].push(doc);
            break;
          case 'pdf':
            attachmentsModel.type = 'pdf';
            doc['type'] = 'pdf';
            isFile = true;
            if (!doc_obj[image_time]) {
              doc_obj[image_time] = [];
            }
            doc_obj[image_time].push(doc);
            if (!all_obj[image_time]) {
              all_obj[image_time] = [];
            }
            all_obj[image_time].push(doc);
            break;
          case 'doc':
          case 'docx':
            attachmentsModel.type = 'doc';
            doc['type'] = 'doc';
            isFile = true;
            if (!doc_obj[image_time]) {
              doc_obj[image_time] = [];
            }
            doc_obj[image_time].push(doc);
            if (!all_obj[image_time]) {
              all_obj[image_time] = [];
            }
            all_obj[image_time].push(doc);
            break;
        }
        attachments.push(attachmentsModel);
      });
    } else {
      if (comment.attachmentCount > 0) {
        tempTimeline_obj.comment = comment;
      }
    }

    if (attachments.length > 0) {
      if (isFile && isImg) {
        let tempObj = Object.assign({}, tempTimeline_obj);
        tempObj.attachments = attachments;
        tempTimeline_obj.attachments = attachments;
        // commentObj.push(tempObj);
      } else {
        tempTimeline_obj.attachments = attachments;
      }
    }
    if (isAudit) {
      timeline_obj.push(tempTimeline_obj);
    }
    commentObj.push(tempTimeline_obj);
  };

  const generateStatusTranslationKey = value => {
    switch (value) {
      case 'in progress':
      case 'created':
        return lang_keys.m_lbl_create.toLowerCase();
      case 'started':
        return lang_keys.m_lbl_start.toLowerCase();
      case 'completed':
        return lang_keys.m_lbl_cmpltd.toLowerCase();
    }
  };

  const getAlternateFilename = filename => {
    //iOS Comment issue fix - Filename is comment section is stored with Capital letters
    return filename.charAt(0).toLowerCase() + filename.slice(1);
  };

  function isISODateString(str) {
    // Regex pattern for ISO date format
    const isoDatePattern = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})(\.\d{1,3})?Z?$/;
    return isoDatePattern.test(str);
  }
  const res = getContentAttachedImages(data, image_obj, all_obj, accessToken, databaseId); // Get attached images added during ticket creation
  image_obj = res.imageObj;
  all_obj = res.allObj;
  if (operations && operations.length > 0) {
    operations.forEach(operation => {
      if (operation.changedProperties && operation.changedProperties.length > 0) {
        author = generateFullName(operation.author, appState, lang_keys, personList) || lang_keys.m_lbl_acc;
        date = generateDateTime(operation.time, appState);
        operation.changedProperties.forEach((property, index) => {
          if (author === config.recurrentEmail) {
            if (property === 'title') {
              tempTimeline_obj = new OperationTimeline(author, date, property);
              const dueDateIndex = operation.changedProperties.findIndex(property => property === 'dueDate');
              const dueDate = operation.newValues[dueDateIndex];
              tempTimeline_obj.message = lang_keys.m_txt_timeline_recurring_audit
                .replace('__NAME__', operation.newValues[index])
                .replace('__DATE__', dateFormatter(dueDate, appState));
              timeline_obj.push(tempTimeline_obj);
              return;
            } else {
              return;
            }
          }
          if (property === 'commentTimestamp') {
            return;
          }
          switch (property) {
            case 'title':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_txt_timeline_title_added_web.toLowerCase().replace('__title_text__', operation.newValues[index]);
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_txt_timeline_title_removed_web.toLowerCase().replace('__old_title__', operation.oldValues[index]);
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_txt_timeline_title_changed_web.toLowerCase().replace('__old_title__', operation.oldValues[index]);
                tempTimeline_obj.message = tempTimeline_obj.message.replace('__new_title__', operation.newValues[index]);
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'consulted':
            case 'informed':
              generateInformedConsultedOperation(operation, property, index);
              break;
            case 'responsible':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = generatePersonMessage(operation.newValues[index], 'assign', lang_keys.m_lbl_resp);
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = generatePersonMessage(operation.oldValues[index], 'revoke', lang_keys.m_lbl_resp);
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                // Revoke old resp
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = generatePersonMessage(operation.oldValues[index], 'revoke', lang_keys.m_lbl_resp);
                timeline_obj.push(tempTimeline_obj); // Push 1st object for revoke

                // Assign new resp
                tempTimeline_obj = new OperationTimeline(author, date, property); // create a new object for assign
                tempTimeline_obj.message = generatePersonMessage(operation.newValues[index], 'assign', lang_keys.m_lbl_resp);
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'duedate':
            case 'dueDate': // Android Support for timeline
              // removed 'operation.newValues[index] &&' condition as we are setting remove duedate to empty string ED-3914
              if (operation.newValues[index] || operation.oldValues[index]) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.newValues[index] === '' || operation.newValues[index] === null) {
                  // tempTimeline_obj.message = `Removed the due date ${dateFormatter(operation.oldValues[index], appState)}.`
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_remove_duedate
                    .toLowerCase()
                    .replace('__selected_date__', dateFormatter(operation.oldValues[index], appState));
                } else {
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_set_duedate_web
                    .toLowerCase()
                    .replace('__selected_date__', dateFormatter(operation.newValues[index], appState));
                }
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'update':
              if (operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.newValues[index].includes('Sent audit to KYP')) {
                  tempTimeline_obj.message = lang_keys.m_lbl_audit_kyp;
                } else {
                  tempTimeline_obj.message = lang_keys.m_lbl_audit_hmcntrl;
                }
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'status':
              let isPastWarning = false;
              let isReccurring = operation.author === 'recurrence.ticket@dutchview.com';

              if (operation.newValues && operation.newValues[index] === 'completed' && !isReccurring) {
                const dueDate = new Date(data.dates.dueDate);
                const completionDate = new Date(operation.time);
                isPastWarning = completionDate > dueDate;
              }
              if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (!isReccurring) {
                  tempTimeline_obj.isPastWarning = isPastWarning;
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_status_change_web.toLowerCase();
                  tempTimeline_obj.message = tempTimeline_obj.message.replace('__person__', '');
                  tempTimeline_obj.message = tempTimeline_obj.message.replace('__old_status__', generateStatusTranslationKey(operation.oldValues[index].toLowerCase()));
                  tempTimeline_obj.message = tempTimeline_obj.message.replace('__new_status__', generateStatusTranslationKey(operation.newValues[index].toLowerCase()));
                } else {
                  tempTimeline_obj.isPastWarning = operation.oldValues[index] !== 'completed';
                  tempTimeline_obj.message = lang_keys.recurring_ticket.reopen;
                }
                timeline_obj.push(tempTimeline_obj);
              } else if (!operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null' && operation.newValues[index] !== 'created') {
                // Fix for Android issue - empty old value
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.isPastWarning = isPastWarning;
                tempTimeline_obj.message = lang_keys.m_txt_timeline_status_change_web.toLowerCase();
                tempTimeline_obj.message = tempTimeline_obj.message.replace('__person__', '');
                tempTimeline_obj.message = tempTimeline_obj.message.replace('__old_status__', generateStatusTranslationKey('in progress'));
                tempTimeline_obj.message = tempTimeline_obj.message.replace('__new_status__', generateStatusTranslationKey(operation.newValues[index].toLowerCase()));
                timeline_obj.push(tempTimeline_obj);
              }

              break;
            case 'reopen':
              tempTimeline_obj = new OperationTimeline(author, date, property);
              tempTimeline_obj.message = lang_keys.m_lbl_reopened_audit;
              timeline_obj.push(tempTimeline_obj);
              break;
            case 'archived':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (!isAudit) {
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_archive_tkt_adt_web.toLowerCase().replace('__ticket_or_audit__', 'ticket');
                } else {
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_archive_tkt_adt_web.toLowerCase().replace('__ticket_or_audit__', 'audit');
                }
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (!isAudit) {
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_dearchive_tkt_adt_web.toLowerCase().replace('__ticket_or_audit__', 'ticket');
                } else {
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_dearchive_tkt_adt_web.toLowerCase().replace('__ticket_or_audit__', 'audit');
                }
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'description':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_txt_timeline_dec_added_web.toLowerCase().replace('__description_text__', operation.newValues[index]);
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_txt_timeline_dec_removed_web.toLowerCase().replace('__description_text__', operation.oldValues[index]);
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_txt_timeline_dec_changed_web.toLowerCase().replace('__old_description__', operation.oldValues[index]);
                tempTimeline_obj.message = tempTimeline_obj.message.replace('__new_description__', operation.newValues[index]);
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'tags':
              //saw timeline with no newValue and oldValue key so this
              if (operation.oldValues && operation.newValues) {
                if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_addedtags.replace('__TAGS__', operation.newValues[index].join(' ; '));
                  timeline_obj.push(tempTimeline_obj);
                } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index].length <= 0)) {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.m_txt_timeline_removetags.replace('__TAGS__', operation.oldValues[index].join(' ; '));
                  timeline_obj.push(tempTimeline_obj);
                } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  let removedTags = operation.oldValues[index].filter(x => {
                    return !operation.newValues[index].includes(x);
                  });
                  let addedTags = operation.newValues[index].filter(x => {
                    return !operation.oldValues[index].includes(x);
                  });
                  if (addedTags.length > 0 && removedTags.length > 0) {
                    tempTimeline_obj.message = lang_keys.m_txt_timeline_addremovetags.replace('__TAGS__', addedTags.join(' ; '));
                    tempTimeline_obj.message = tempTimeline_obj.message.replace('__TAGS__', removedTags.join(';'));
                  } else if (addedTags.length > 0) {
                    tempTimeline_obj.message = lang_keys.m_txt_timeline_addedtags.replace('__TAGS__', addedTags.join(' ; '));
                  } else if (removedTags.length > 0) {
                    tempTimeline_obj.message = lang_keys.m_txt_timeline_removetags.replace('__TAGS__', removedTags.join(' ; '));
                  }
                  timeline_obj.push(tempTimeline_obj);
                }
              }
              break;
            case 'comment':
              let time = operation.time,
                userRole: any = getRole('all', appState);
              const loggedInUserId = appState.get('id', 'user');
              if (comments.length > 0) {
                let result = comments.filter(comment => {
                  return comment.time === time;
                });
                if (result && result.length > 0) {
                  if (
                    getDocLevelRole(data, appState) !== 'reporter' ||
                    (getDocLevelRole(data, appState) === 'reporter' && result[0].author === loggedInUserId) ||
                    userRole.accountable ||
                    userRole.support ||
                    userRole.contractAdmin
                  ) {
                    if (result[0].public || isAudit) {
                      generateCommentOperation(result[0], data, operation, accessToken);
                    } else {
                      if (result[0].allowedUsers && result[0].allowedUsers.length > 0 && result[0].allowedUsers.includes(appState.get('id', 'user'))) {
                        generateCommentOperation(result[0], data, operation, accessToken);
                      } else if (userRole.superAdmin || userRole.accountable || userRole.support || userRole.contractAdmin) {
                        generateCommentOperation(result[0], data, operation, accessToken);
                      }
                    }
                  }
                }
              }
              break;

            case 'removedComment':
              let timestamp;
              const userRoles: any = getRole('all', appState);
              const loggedInUser = appState.get('id', 'user');
              if (
                getDocLevelRole(data, appState) !== 'reporter' ||
                (getDocLevelRole(data, appState) === 'reporter' && author === loggedInUser) ||
                userRoles.accountable ||
                userRoles.support ||
                userRoles.contractAdmin
              ) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.changedProperties[index + 1] === 'commentTimestamp') {
                  timestamp = operation.newValues[index + 1];
                } else {
                  const indexComment = operation.changedProperties.findIndex(prop => prop === 'commentTimestamp');
                  if (indexComment > -1) timestamp = operation.newValues[indexComment];
                }
                let result = comments.filter(comment => {
                  return comment.time === timestamp;
                });

                if (timestamp) {
                  tempTimeline_obj.message = lang_keys.delete_attachment.removed
                    .replace('__NEW__', result[0].attachmentCount - operation.newValues[index].length)
                    .replace('__TIME__', generateDateTime(timestamp, appState))
                    .replace('__TOTAL__', result[0].attachmentCount || 0);
                } else {
                  tempTimeline_obj.message = lang_keys.delete_attachment.removed_content.replace('__COUNT__', 1);
                }
                if (isAudit) {
                  timeline_obj.push(tempTimeline_obj);
                }
                commentObj.push(tempTimeline_obj);
              }
              break;
            case 'recurrentPeriodStart':
              const oldValue = operation.oldValues[index];
              const newValue = operation.newValues[index];
              const isOldValueNull = !oldValue || oldValue === 'null';
              const isNewValueNull = !newValue || newValue === 'null';

              if (!isOldValueNull && !isNewValueNull) {
                const tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.recurring_ticket.edit_start_date.replace('__DATE__', dateFormatter(newValue, appState));
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'recurrentPeriodEnd':
              const oldValueEndDate = operation.oldValues[index];
              const newValueEndDate = operation.newValues[index];
              const isOldValueNullEndDate = !oldValueEndDate || oldValueEndDate === 'null';
              const isNewValueNullEndDate = !newValueEndDate || newValueEndDate === 'null';

              if (!isOldValueNullEndDate && !isNewValueNullEndDate) {
                const tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.recurring_ticket.edit_end_date.replace('__DATE__', dateFormatter(newValueEndDate, appState));
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            case 'rrule':
              const freqText = {
                DAILY: lang_keys.recurring_audit.day,
                WEEKLY: lang_keys.recurring_audit.week,
                MONTHLY: lang_keys.recurring_audit.month,
              };
              const indexComment = operation.changedProperties.findIndex(prop => prop === 'isRecurrenceEnabled');

              const previousValue = operation.oldValues[index];
              const currentValue = operation.newValues[index];
              const isPreviousValueNull = !previousValue || previousValue === 'null';
              const isCurrentValueNull = !currentValue || currentValue === 'null';

              if (!isPreviousValueNull && !isCurrentValueNull && indexComment === -1) {
                const recurrenceRule = parseRRule(currentValue);
                const recurrenceRuleOld = parseRRule(previousValue);
                const timelineEntry = new OperationTimeline(author, date, property);

                timelineEntry.message = lang_keys.recurring_ticket.rrule_edit
                  .replace('__NEW__', `${recurrenceRule.INTERVAL} ${freqText[recurrenceRule.FREQ]}`)
                  .replace('__OLD__', `${recurrenceRuleOld.INTERVAL} ${freqText[recurrenceRuleOld.FREQ]}`);

                timeline_obj.push(timelineEntry);
              }

              break;
            case 'isRecurrenceEnabled':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                let rrule, recurrenceRule;
                const freqText = {
                  DAILY: lang_keys.recurring_audit.day,
                  WEEKLY: lang_keys.recurring_audit.week,
                  MONTHLY: lang_keys.recurring_audit.month,
                };
                const indexComment = operation.changedProperties.findIndex(prop => prop === 'rrule');
                if (indexComment > -1) rrule = operation.newValues[indexComment];
                if (rrule) {
                  recurrenceRule = parseRRule(rrule);
                  tempTimeline_obj.message = lang_keys.recurring_ticket.enabled_rrule.replace('__DAYS__', `${recurrenceRule.INTERVAL} ${freqText[recurrenceRule.FREQ]}`);
                } else {
                  tempTimeline_obj.message = lang_keys.recurring_ticket.enabled;
                }
                timeline_obj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.recurring_ticket.disabled;
                timeline_obj.push(tempTimeline_obj);
              }
              break;
            default:
              if (property.startsWith('customFields:')) {
                let label = '';
                const pattern = /customFields:(.*)/;
                const match = property.match(pattern);
                if (match) {
                  label = match[1].trim();
                }
                if (
                  (!operation.oldValues[index] || operation.oldValues[index] === 'null' || (Array.isArray(operation.oldValues[index]) && operation.oldValues[index].length <= 0)) &&
                  operation.newValues[index] &&
                  operation.newValues[index] !== 'null'
                ) {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  let value = operation.newValues[index];
                  if (isISODateString(value)) {
                    value = dateFormatter(value);
                  }
                  if (Array.isArray(value)) {
                    value = value.join(' ');
                  }
                  tempTimeline_obj.message = lang_keys.custom_fields.add_operation.toLowerCase().replace('__value__', value).replace('__label__', label);
                  timeline_obj.push(tempTimeline_obj);
                } else if (
                  operation.oldValues[index] &&
                  operation.oldValues[index] !== 'null' &&
                  (!operation.newValues[index] || operation.newValues[index] === 'null' || (Array.isArray(operation.newValues[index]) && operation.newValues[index].length <= 0))
                ) {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.custom_fields.remove_operation.toLowerCase().replace('__label__', label);
                  timeline_obj.push(tempTimeline_obj);
                } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                  tempTimeline_obj = new OperationTimeline(author, date, property);
                  let value = operation.newValues[index];
                  let oldValue = operation.oldValues[index];
                  if (Array.isArray(value)) {
                    value = value.join(' ');
                  }
                  if (Array.isArray(oldValue)) {
                    oldValue = oldValue.join(' ');
                  }
                  if (isISODateString(value)) {
                    value = dateFormatter(value);
                    oldValue = dateFormatter(oldValue);
                  }
                  tempTimeline_obj.message = lang_keys.custom_fields.update_operation
                    .toLowerCase()
                    .replace('__new__', value)
                    .replace('__label__', label)
                    .replace('__old__', oldValue);

                  timeline_obj.push(tempTimeline_obj);
                }
                break;
              }
              break;
          }
        });
      }
    });
  }
  return { timeline_obj: timeline_obj.reverse(), commentObj: commentObj.reverse(), image_obj, doc_obj, all_obj };
};

const getTicketTimelineObject = (operation, operation_type, old_value, new_value, current_time, appState, ShareComments?) => {
  let timeline_obj: any = {},
    attachment = false;
  timeline_obj.actor = {
    email: appState.get('id', 'user'),
    interface: 'web',
    interfaceVersion: appState.get('version', 'product'),
    type: 'IB.EdBundle.Document.Person',
  };
  timeline_obj.time = current_time;
  // Timeline for due date change
  if (operation_type === 'dueDateChange') {
    timeline_obj.changes = {};
    timeline_obj.changes.dueDate = new_value;
    timeline_obj.type = 'IB.EdBundle.Document.PlanChange';
    return timeline_obj;
  }
  // For addinmg or upating of title
  if (operation_type === 'titleAdd' || operation_type === 'titleChange') {
    timeline_obj.operationType = 'title';
    if (new_value) {
      timeline_obj.newValue = new_value;
    }
    timeline_obj.operation = 'added';
    if (old_value || operation_type === 'titleChange') {
      timeline_obj.oldValue = old_value;
      timeline_obj.operation = 'changed';
    }
    if (old_value !== new_value && new_value === '') {
      timeline_obj.operation = 'removed';
    }
    timeline_obj.type = 'IB.EdBundle.Document.Edit';
    return timeline_obj;
  }
  // For adding or updating description
  if (operation_type === 'descriptionAdd' || operation_type === 'descriptionChange') {
    timeline_obj.operationType = 'description';
    if (new_value) {
      timeline_obj.newValue = new_value;
    }
    timeline_obj.operation = 'added';
    if (old_value || operation_type === 'descriptionChange') {
      timeline_obj.oldValue = old_value;
      timeline_obj.operation = 'changed';
    }
    if (old_value !== new_value && new_value === '') {
      timeline_obj.operation = 'removed';
    }
    timeline_obj.type = 'IB.EdBundle.Document.Edit';
    return timeline_obj;
  }
  // For assigning or removing of participants
  if (operation_type === 'revoke' || operation_type === 'assign') {
    timeline_obj.person = {
      email: new_value,
      type: 'IB.EdBundle.Document.Person',
    };
    if (operation) {
      operation = operation.split('.')[1];
    }
    timeline_obj.operation = operation_type;
    timeline_obj.role = operation;
    timeline_obj.type = 'IB.EdBundle.Document.Assignment';
    return timeline_obj;
  }
  // For state change
  if (operation_type === 'statusAdd') {
    if (old_value) {
      timeline_obj.start = old_value;
      if (new_value.toLowerCase() === 'completed') {
        timeline_obj.transition = 'overrule_to_completed';
      }
      if (new_value.toLowerCase() === 'started') {
        timeline_obj.transition = 'overrule_to_started';
      }
      if (new_value.toLowerCase() === 'created') {
        timeline_obj.transition = 'overrule_to_created';
      }
    } else {
      timeline_obj.start = new_value;
      timeline_obj.transition = 'start_on_create'; //"overrule_to_Completed";
    }
    timeline_obj.type = 'IB.EdBundle.Document.Transition';
    return timeline_obj;
  }
  // For tags change
  if (operation_type === 'tagsAdd' || operation_type === 'tagsChanged') {
    timeline_obj.operationType = 'tags';
    if (new_value) {
      timeline_obj.newValue = new_value;
    }
    timeline_obj.operation = 'added';
    timeline_obj.oldValue = [];
    if (old_value.length > 0 || operation_type === 'tagsChanged') {
      timeline_obj.oldValue = old_value;
      timeline_obj.operation = 'changed';
    }
    timeline_obj.type = 'IB.EdBundle.Document.Tags';
    return timeline_obj;
  }
  // End tags
  if (operation_type === 'archived') {
    if (old_value === 'created') {
      timeline_obj.start = 'created';
      timeline_obj.transition = 'create_on_archived';
    }
    if (old_value === 'started') {
      timeline_obj.start = 'started';
      timeline_obj.transition = 'start_on_archived';
    }
    if (old_value === 'completed') {
      timeline_obj.start = 'completed';
      timeline_obj.transition = 'complete_on_archived';
    }
    timeline_obj.type = 'IB.EdBundle.Document.Transition';
    return timeline_obj;
  }
  if (operation_type === 'unarchived') {
    if (new_value === 'created') {
      timeline_obj.start = 'create_on_archived';
    }
    if (new_value === 'started') {
      timeline_obj.start = 'start_on_archived';
    }
    if (new_value === 'completed') {
      timeline_obj.start = 'complete_on_archived';
    }
    timeline_obj.transition = new_value;
    timeline_obj.type = 'IB.EdBundle.Document.Transition';
    return timeline_obj;
  }
  if (operation_type === 'reopen') {
    if (new_value === 'In Progress') {
      timeline_obj.start = 'created';
      timeline_obj.transition = 'created_on_reopened';
    }
    if (new_value === 'started') {
      timeline_obj.start = 'started';
      timeline_obj.transition = 'start_on_reopened';
    }
    if (new_value === 'completed') {
      timeline_obj.start = 'completed';
      timeline_obj.transition = 'complete_on_reopened';
    }
    return timeline_obj;
  }
  if (operation_type === 'attachment') {
    //new_value will be an array of document attachments.
    //old_value will be ana array of image attachments.
    //comment contains description added

    timeline_obj.author = {
      email: appState.get('id', 'user'),
      interface: 'web',
      interfaceVersion: appState.get('version', 'product'),
      type: 'IB.EdBundle.Document.Person',
    };
    delete timeline_obj.actor;
    if (ShareComments.emails) {
      timeline_obj.share = {
        public: ShareComments.public, //true or false
        email: ShareComments.emails,
      };
    }
    if (new_value.length > 0) {
      attachment = true;
      timeline_obj.docAttachments = new_value;
      if (ShareComments.note) {
        timeline_obj.body = ShareComments.note;
      }
      timeline_obj.type = 'IB.EdBundle.Document.Note';
    }
    if (old_value.length > 0) {
      attachment = true;
      timeline_obj.attachments = old_value;
      if (ShareComments.note) {
        timeline_obj.body = ShareComments.note;
      }
      timeline_obj.type = 'IB.EdBundle.Document.Note';
    }
    if (!attachment) {
      if (ShareComments.note) {
        timeline_obj.body = ShareComments.note;
      }
      timeline_obj.type = 'IB.EdBundle.Document.Note';
    }
    return timeline_obj;
  }
};

const updateType = function (operation: string): string {
  switch (operation) {
    case 'projectCreation':
      return 'IB.EdBundle.Document.Project';
    case 'locationAdd':
    case 'locationChange':
    case 'nameAdd':
    case 'referenceNameAdd':
    case 'nameChange':
    case 'referenceNameChange':
      return 'IB.EdBundle.Document.Change';
    case 'assign':
    case 'revoke':
      return 'IB.EdBundle.Document.Assignment';
    default:
      return '';
  }
};

const getProjectTimelineObject = (operation, operation_type, old_value, new_value, current_time, appState) => {
  //operation, operation_type, old_value, new_value, current_time
  let timeline_obj: any = {};
  // Actor, Person who is responsible for the action.

  timeline_obj.actor = {
    email: appState.get('id', 'user'),
    interface: 'web',
    interfaceVersion: appState.get('version', 'product'),
    type: 'IB.EdBundle.Document.Person',
  };
  timeline_obj.operation = operation;
  timeline_obj.operationType = operation_type;
  timeline_obj.time = current_time;
  timeline_obj.type = updateType(operation_type);
  //Add filed "person" only if partiicipants is updated
  if (operation_type === 'assign' || operation_type === 'revoke') {
    timeline_obj.person = {};
    timeline_obj.person.email = new_value;
    timeline_obj.person.type = 'IB.EdBundle.Document.Person';
  }
  if (operation_type !== 'assign' && operation_type !== 'revoke') {
    timeline_obj.oldValue = old_value;
    timeline_obj.newValue = new_value;
  }
  if (operation_type === 'archived' || operation_type === 'unarchived') {
    timeline_obj.oldValue = old_value;
    timeline_obj.newValue = new_value;
    timeline_obj.type = 'IB.EdBundle.Document.Transition';
  }
  return timeline_obj;
};

const generateContractTimelineView = function (operations, lang_keys, appState, personList) {
  const operationTimeline = function (author, date, type) {
    const data = {
      type: type || '',
      date: date || '',
      author: author || '',
      message: '',
      attachments: [],
    };
    return data;
  };

  const generatePersonMessage = (value, type, property) => {
    let message = '';
    if (type === 'revoke') {
      message = lang_keys.m_txt_timeline_revokerole_web.replace('__PERSON_2__', generateFullName(value, appState, lang_keys, personList));
      message = message.replace('__ROLE__', property);
    } else {
      message = lang_keys.m_txt_timeline_assignrole_web.toLowerCase().replace('__person_2__', generateFullName(value, appState, lang_keys, personList));
      message = message.replace('__role__', property);
    }
    return message;
  };

  const generateInformedConsultedSupportOperation = function (operation, property, index) {
    let type_lang_key;
    if (property === 'managers') {
      type_lang_key = lang_keys.m_lbl_manager;
    } else if (property === 'administrators') {
      type_lang_key = lang_keys.m_lbl_administrator;
    } else if (property === 'invoiceEmails') {
      type_lang_key = 'invoice email';
    }

    if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
      operation.newValues[index].forEach(value => {
        tempTimeline_obj = operationTimeline(author, date, property);
        tempTimeline_obj.message = generatePersonMessage(value, 'assign', type_lang_key);
        timeLineObj.push(tempTimeline_obj);
      });
    } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
      // Removing all consulted role
      operation.oldValues[index].forEach(value => {
        tempTimeline_obj = operationTimeline(author, date, property);
        tempTimeline_obj.message = generatePersonMessage(value, 'revoke', type_lang_key);
        timeLineObj.push(tempTimeline_obj);
      });
    } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
      operation.oldValues &&
        operation.oldValues[index] &&
        operation.oldValues[index].forEach(value => {
          if (!operation.newValues[index].includes(value)) {
            tempTimeline_obj = operationTimeline(author, date, property);
            tempTimeline_obj.message = generatePersonMessage(value, 'revoke', type_lang_key);
            timeLineObj.push(tempTimeline_obj);
          }
        });
      operation.newValues[index] &&
        operation.newValues[index].forEach(value => {
          if (!operation.oldValues[index].includes(value)) {
            tempTimeline_obj = operationTimeline(author, date, property);
            tempTimeline_obj.message = generatePersonMessage(value, 'assign', type_lang_key);
            timeLineObj.push(tempTimeline_obj);
          }
        });
    }
  };

  const getTranslationKey = (property, type) => {
    switch (property) {
      case 'city':
        if (type === 'add') {
          return lang_keys.contract.city_add_timeline;
        } else if (type === 'edit') {
          return lang_keys.contract.city_edit_timeline;
        } else {
          return lang_keys.contract.city_remove_timeline;
        }
      case 'streetName':
        if (type === 'add') {
          return lang_keys.contract.street_add_timeline;
        } else if (type === 'edit') {
          return lang_keys.contract.street_edit_timeline;
        } else {
          return lang_keys.contract.street_remove_timeline;
        }
      case 'country':
        if (type === 'add') {
          return lang_keys.contract.country_add_timeline;
        } else if (type === 'edit') {
          return lang_keys.contract.country_edit_timeline;
        } else {
          return lang_keys.contract.country_remove_timeline;
        }
      case 'zipCode':
        if (type === 'add') {
          return lang_keys.contract.zip_add_timeline;
        } else if (type === 'edit') {
          return lang_keys.contract.zip_edit_timeline;
        } else {
          return lang_keys.contract.zip_remove_timeline;
        }
      case 'contactPersonName':
        if (type === 'add') {
          return lang_keys.contract.person_add_timeline;
        } else if (type === 'edit') {
          return lang_keys.contract.person_edit_timeline;
        } else {
          return lang_keys.contract.person_remove_timeline;
        }

      default:
        break;
    }
  };
  let timeLineObj = [],
    tempTimeline_obj,
    author,
    date;
  if (operations && operations.length > 0) {
    operations.forEach((operation, idx: number) => {
      if (operation.changedProperties && operation.changedProperties.length > 0 && operation.author && operation.time) {
        author = generateFullName(operation.author, appState, lang_keys, personList) || lang_keys.m_lbl_acc;
        date = operation.time ? generateDateTime(operation.time, appState) : lang_keys.m_lbl_na;
        operation.changedProperties.forEach((property, index: number) => {
          switch (property) {
            case 'name':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.name_add_timeline + ' ' + operation.newValues[index];
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.name_edit_timeline.replace('__OLD__', operation.oldValues[index]).replace('__NEW__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.name_remove_timeline + ' ' + operation.oldValues[index];
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'referenceName':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.ref_add_timeline + ' ' + operation.newValues[index];
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.ref_edit_timeline.replace('__OLD__', operation.oldValues[index]).replace('__NEW__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.ref_remove_timeline + ' ' + operation.oldValues[index];
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'archived':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.archived_timeline;
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.dearchived_timeline;
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'project':
              const getProjectName = () => {
                if (operations[idx + 1].changedProperties[0] === 'movedProject') {
                  return operations[idx + 1].newValues[0];
                } else {
                  return 'Project X';
                }
              };
              if (operation.oldValues[index] && operation.newValues[index] && operation.oldValues.length == operation.newValues.length) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.m_lbl_from_contract
                  .replace('__PROJECT__', getProjectName())
                  .replace('__OLD__', operation.oldValues[index])
                  .replace('__NEW__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'mfaEnabled':
              if (
                (!operation.oldValues[index] || operation.oldValues[index] === 'null' || operation.oldValues[index] === 'false') &&
                operation.newValues[index] &&
                operation.newValues[index] !== 'null' &&
                operation.newValues[index] !== 'false'
              ) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.mfa_enable_timeline;
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                if (idx !== 0) {
                  // On creation it will always be 0 (field not visible on time of creation)
                  tempTimeline_obj = operationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.contract.mfa_disable_timeline;
                  timeLineObj.push(tempTimeline_obj);
                }
              }
              break;
            case 'managers':
            case 'administrators':
            case 'invoiceEmails':
              generateInformedConsultedSupportOperation(operation, property, index);
              break;
            case 'phoneNumber':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.phone_add_timeline + ' ' + operation.newValues[index];
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.phone_edit_timeline.replace('__OLD__', operation.oldValues[index]).replace('__NEW__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.phone_remove_timeline + ' ' + operation.oldValues[index];
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'geomap':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.project_timeline.enable_geomap;
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                if (idx !== 0) {
                  // On creation it will always be 0 (field not visible on time of creation)
                  tempTimeline_obj = operationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.project_timeline.disable_geomap;
                  timeLineObj.push(tempTimeline_obj);
                }
              }
              break;
            case 'reporter':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = lang_keys.contract.reporter_enable_timeline;
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                if (idx !== 0) {
                  // On creation it will always be 0 (field not visible on time of creation)
                  tempTimeline_obj = operationTimeline(author, date, property);
                  tempTimeline_obj.message = lang_keys.contract.reporter_disable_timeline;
                  timeLineObj.push(tempTimeline_obj);
                }
              }
              break;
            case 'city':
            case 'streetName':
            case 'country':
            case 'zipCode':
            case 'contactPersonName':
              if ((!operation.oldValues[index] || operation.oldValues[index] === 'null') && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = getTranslationKey(property, 'add') + ' ' + operation.newValues[index];
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = getTranslationKey(property, 'edit').replace('__OLD__', operation.oldValues[index]).replace('__NEW__', operation.newValues[index]);
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && operation.oldValues[index] !== 'null' && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = operationTimeline(author, date, property);
                tempTimeline_obj.message = getTranslationKey(property, 'remove') + ' ' + operation.oldValues[index];
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            case 'attachment':
              if (!operation.oldValues[index] && operation.newValues[index] && operation.newValues[index] !== 'null') {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.newValues[index].includes('logo')) {
                  tempTimeline_obj.message = lang_keys.project_timeline.add_logo;
                }
                timeLineObj.push(tempTimeline_obj);
              } else if (operation.oldValues[index] && (!operation.newValues[index] || operation.newValues[index] === 'null')) {
                tempTimeline_obj = new OperationTimeline(author, date, property);
                if (operation.newValues[index].includes('logo')) {
                  tempTimeline_obj.message = lang_keys.project_timeline.edit_logo;
                }
                timeLineObj.push(tempTimeline_obj);
              }
              break;
            default:
              break;
          }
        });
      }
    });
  }

  return timeLineObj.reverse();
};

export { timelineForMapsModule, prepareOperationTimeline, generateTimelineData, getTicketTimelineObject, getProjectTimelineObject, generateContractTimelineView };
